<template>
  <div class="quiz-container">
    <div v-if="!quizStarted" class="welcome-screen">
      <h1>Mediabuyer Quiz</h1>
      <registration-form @start-quiz="startQuiz" />
    </div>
    
    <div v-else-if="!introductionRead" class="introduction">
      <h2>Welcome to the Quiz</h2>
      <div class="intro-text">
        <p>We are a performance marketing agency specializing in lead generation for the home services niche across the United States. Our mission is to deliver high-quality leads to our partners, and we pride ourselves on our expertise in platforms like Facebook, Google, and TikTok. With a proven track record of success, we are now looking to scale our revenue by onboarding talented and innovative media buyers to join our team.</p>
        
        <p>This quiz is designed to evaluate your skills, creativity, and strategic thinking as a media buyer. It's an opportunity for you to demonstrate your understanding of performance marketing. The case study below will focus on a home security offer, and we encourage you to approach it as if you were running a live campaign for us.</p>
        
        <p>We specialize in generating leads for our clients, but the ultimate goal is to drive calls, as these are more likely to convert into high-quality sales opportunities. Keep this objective in mind as you craft your responses.</p>
        
        <p>Take your time to provide detailed and thoughtful responses. Remember, we're not only assessing your knowledge but also your ability to think strategically and execute effectively.</p>

        <h3>Case Study: Home Security Offer</h3>
        <p>Offer Description: Home security systems provide families and individuals with protection against break-ins, fire, and other emergencies. These systems typically include features like motion detectors, security cameras, alarm notifications, and 24/7 monitoring services.</p>

        <div class="rules">
          <h3>Quiz Rules:</h3>
          <ul>
            <li>You have a maximum of 30 minutes to complete this quiz</li>
            <li>Please provide detailed answers for long-form questions</li>
            <li>You cannot go back to previous questions once submitted</li>
            <li>Make sure to complete all questions before the timer runs out</li>
          </ul>
        </div>
      </div>
      <button @click="startQuestions" class="btn-primary">Start Quiz</button>
    </div>

    <div v-else-if="!quizCompleted" class="quiz-section">
      <div class="timer">Time remaining: {{ formatTime }}</div>
      <quiz-component 
        :currentQuestion="questions[currentQuestionIndex]"
        :questionNumber="currentQuestionIndex + 1"
        :totalQuestions="questions.length"
        :answers="answers"
        @answer-submitted="handleAnswer"
        @go-back="handleGoBack"
      />
    </div>

    <div v-else class="completion-screen">
      <h2>Thank you for completing the quiz!</h2>
      <p>Your responses have been recorded and will be sent to our team.</p>
    </div>
  </div>
</template>

<script>
import RegistrationForm from './components/RegistrationForm.vue'
import QuizComponent from './components/QuizComponent.vue'
import { questions } from './data/questions'
import emailjs from 'emailjs-com'

// Initialize EmailJS with your user ID
emailjs.init("blvwOGNvD4sRPqGjC", { publicKey: true })  // Specify that we're using a public key

// Key for storing completed emails in localStorage
const COMPLETED_EMAILS_KEY = 'completed_quiz_emails'

export default {
  name: 'App',
  components: {
    RegistrationForm,
    QuizComponent
  },
  data() {
    return {
      quizStarted: false,
      introductionRead: false,
      quizCompleted: false,
      currentQuestionIndex: 0,
      questions: questions,
      answers: {},
      userData: null,
      timeRemaining: 1800, // 30 minutes in seconds
      timer: null
    }
  },
  computed: {
    formatTime() {
      const minutes = Math.floor(this.timeRemaining / 60)
      const seconds = this.timeRemaining % 60
      return `${minutes}:${seconds.toString().padStart(2, '0')}`
    }
  },
  methods: {
    checkEmailCompleted(email) {
      const completedEmails = JSON.parse(localStorage.getItem(COMPLETED_EMAILS_KEY) || '[]')
      return completedEmails.includes(email)
    },
    addCompletedEmail(email) {
      const completedEmails = JSON.parse(localStorage.getItem(COMPLETED_EMAILS_KEY) || '[]')
      completedEmails.push(email)
      localStorage.setItem(COMPLETED_EMAILS_KEY, JSON.stringify(completedEmails))
    },
    startQuiz(userData) {
      // Check if email has already completed the quiz
      if (this.checkEmailCompleted(userData.email)) {
        alert('This email has already been used to complete the quiz. Only one submission per email is allowed.')
        return
      }
      this.userData = userData
      this.quizStarted = true
    },
    startQuestions() {
      this.introductionRead = true
      this.startTimer()
    },
    startTimer() {
      this.timer = setInterval(() => {
        if (this.timeRemaining > 0) {
          this.timeRemaining--
        } else {
          this.completeQuiz()
        }
      }, 1000)
    },
    handleAnswer(answer) {
      this.answers[this.currentQuestionIndex] = answer
      console.log('Answer recorded:', answer)
      
      if (this.currentQuestionIndex < this.questions.length - 1) {
        this.currentQuestionIndex++
      } else {
        this.completeQuiz()
      }
    },
    handleGoBack() {
      if (this.currentQuestionIndex > 0) {
        this.currentQuestionIndex--
      }
    },
    async completeQuiz() {
      clearInterval(this.timer)
      this.quizCompleted = true
      
      // If timer ran out, reset and return to start
      if (this.timeRemaining <= 0) {
        alert('Time is up! Please try again.')
        this.resetQuiz()
        return
      }
      
      // Prepare email content
      const emailContent = {
        to_name: 'Zaur',
        from_name: this.userData.name,
        from_email: this.userData.email,
        reply_to: this.userData.email,
        message: `
          ====================================
          REGISTRATION INFORMATION
          ====================================
          Full Name: ${this.userData.name}
          Email Address: ${this.userData.email}
          Years of Experience: ${this.userData.experience}
          Submission Time: ${new Date().toLocaleString()}
  
          ====================================
          QUIZ ANSWERS
          ====================================
          ${Object.entries(this.answers).map(([index, answer]) => `
          Question ${Number(index) + 1}: ${this.questions[index].question}
          Answer: ${Array.isArray(answer) ? answer.join(', ') : answer}
          `).join('\n')}
  
          ====================================
          END OF SUBMISSION
          ====================================
        `
      }

      try {
        await emailjs.send(
          'service_7u75awt',
          'template_l9dw5pp',
          emailContent,
          'blvwOGNvD4sRPqGjC'  // Add public key as the fourth parameter
        )
        console.log('Email sent successfully!')
        console.log('Registration and Quiz Data:', {
          registration: this.userData,
          answers: this.answers
        })
        // Add email to completed list after successful submission
        this.addCompletedEmail(this.userData.email)
      } catch (error) {
        console.error('Failed to send email:', error)
        alert('Quiz completed! However, there was an issue sending the email. Please contact support.')
      }
    },
    resetQuiz() {
      // Reset all quiz state
      this.quizStarted = false
      this.introductionRead = false
      this.quizCompleted = false
      this.currentQuestionIndex = 0
      this.answers = {}
      this.userData = null
      this.timeRemaining = 1800 // Reset timer to 30 minutes
      if (this.timer) {
        clearInterval(this.timer)
        this.timer = null
      }
    },
    beforeDestroy() {
      if (this.timer) {
        clearInterval(this.timer)
      }
    }
  }
}
</script>

<style>
@import './assets/styles/global.css';

.quiz-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.introduction {
  margin: 20px 0;
  padding: 30px;
  background-color: rgba(106, 90, 205, 0.1);
  border-radius: 8px;
}

.intro-text {
  margin: 20px 0;
  line-height: 1.6;
  color: var(--neutral-gray);
}

.intro-text p {
  margin-bottom: 15px;
  color: var(--primary-text);
}

.rules {
  background-color: rgba(255, 111, 97, 0.1);
  padding: 20px;
  border-radius: 4px;
  margin: 20px 0;
  border-left: 4px solid var(--accent-pink);
}

.rules ul {
  list-style-type: disc;
  margin-left: 20px;
}

.rules li {
  margin: 10px 0;
}

.btn-primary {
  background-color: var(--accent-pink);
  color: var(--primary-text);
  padding: 12px 24px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  transition: all 0.3s ease;
}

.btn-primary:hover {
  background-color: #ff8577;
  transform: translateY(-2px);
}

.btn-secondary {
  background-color: var(--accent-purple);
  color: var(--primary-text);
  padding: 12px 24px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  transition: all 0.3s ease;
}

.btn-secondary:hover {
  background-color: #7b6dde;
  transform: translateY(-2px);
}

.timer {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: var(--accent-purple);
  padding: 10px 20px;
  border-radius: 4px;
  font-weight: 600;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

h1, h2, h3 {
  color: var(--primary-text);
}

h1 {
  font-size: 2em;
  margin-bottom: 30px;
  background: linear-gradient(45deg, var(--accent-pink), var(--accent-purple));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
}

h2 {
  font-size: 1.5em;
  margin-bottom: 20px;
}

h3 {
  font-size: 1.2em;
  margin: 20px 0 15px 0;
}
</style> 