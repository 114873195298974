export const questions = [
  {
    type: 'long-text',
    question: 'Describe how you would present the home security offer. Highlight the key benefits, unique selling points (USPs), and how it addresses potential customers\' pain points.'
  },
  {
    type: 'multi-select',
    question: 'Which demographic group is most likely to be interested in a home security system?',
    options: [
      '18-25',
      '25-35',
      '35-45',
      '45-55',
      'Senior citizens (55+)',
      'All of the above'
    ]
  },
  {
    type: 'short-text',
    question: 'Identify the key pain points of the target audience for this offer and explain how the home security system addresses those issues.'
  },
  {
    type: 'short-text',
    question: 'List one affiliate network where you would search for a home security offer and why you chose it.'
  },
  {
    type: 'multi-select',
    question: 'Which factor is most important when selecting an affiliate offer?',
    options: [
      'High payout per call',
      'Conversion rate',
      'Reputation of the network',
      'All of the above'
    ]
  },
  {
    type: 'long-text',
    question: 'Using any spy tool you prefer, find similar ads for home security systems. Describe the ads you found and why they caught your attention. Include the name of the tool you used and why it was your choice.'
  },
  {
    type: 'long-text',
    question: 'Provide url examples of what type of landing page you would use to drive traffic to a home security offer. Include details about layout, messaging, visuals, and specific elements that would encourage visitors to convert into calls.'
  },
  {
    type: 'single-select',
    question: 'Which platform would you prioritize for running this campaign?',
    options: [
      'Facebook Ads',
      'Google Ads',
      'TikTok Ads',
      'Other'
    ]
  },
  {
    type: 'long-text',
    question: 'Explain your chosen traffic source from the previous question. Include details about targeting, budget allocation, and how you would optimize conversions.'
  },
  {
    type: 'short-text',
    question: 'Name one key metric you would monitor to ensure profitability.'
  },
  {
    type: 'long-text',
    question: 'Outline your strategy to scale the campaign while maintaining a positive return on investment (ROI).'
  }
] 